<template>
  <div @click="documents = []">
    <file-upload
      v-model="documents"
      class="file-upload-input"
      :accept="acceptedFiles"
      :name="elnameName"
      data-vv-as=" documents"
      :multiple="allowMultiple"
      @input="upload(documents, 'document')"
    >
      <img
        class="file-icon"
        src="@/assets/images/upload_icon.svg"
        @click="documents = []"
      />
      Upload {{ title }}
    </file-upload>
  </div>
</template>

<script>
//import { ValidationObserver, ValidationProvider } from "vee-validate";
import FileUpload from "vue-upload-component/src";
import _ from "lodash";

export default {
  name: "phoneInput",
  components: {
    //ValidationObserver,
    //ValidationProvider,
    FileUpload,
  },
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    secureType: {
      type: String,
      default: "private",
    },
    title: {
      type: String,
      default: "",
    },
    indexValue: {
      type: Number,
      default: 0,
    },

    payLoadKey: {
      type: String,
      default: "",
    },
    acceptedFiles: {
      type: String,
      default: "*",
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    payLoadDocumentsKey: {
      type: String,
      default: "documents",
    },
    takeIndex: {
      type: Boolean,
      default: true,
    },
    //zip,application/zip,application/x-zip,application/x-zip-compressed
  },
  data() {
    return {
      elnameName: "fl",
      documents: [],
      finalDocuments: null,
    };
  },
  mounted() {
    this.elnameName = this.randomStr();
    this.documents = [];
  },
  methods: {
    upload(model) {
      this.finalDocuments = [];

      let temp_count = 0;
      // debugger; // eslint-disable-line no-debugger

      let mapper = model.map(
        (item) =>
          (item = {
            name: item.name,
            file: item.file ? item.file : null,
            path: item.url ? item.url : "",
            url: item.url ? item.url : "",
            extn: item.name.split(".").pop(),
            mimetype: item.type ? item.type : item.mimetype,
          })
      );

      //var ext = mapper[0].extn.split('.').pop();

      if (mapper.length > 0) {
        // debugger; // eslint-disable-line no-debugger

        mapper.forEach((doc, index) => {
          let formData = new FormData();

          // debugger; // eslint-disable-line no-debugger

          formData.append("files", doc.file);
          formData.append("secureType", this.secureType);
          this.$store.dispatch("uploadS3File", formData).then((response) => {
            temp_count++;
            response.data.result.forEach((urlGenerated) => {
              if (_.has(urlGenerated, "path") && !_.has(urlGenerated, "url")) {
                urlGenerated["url"] = urlGenerated["path"];
              }
              if (_.has(urlGenerated, "url") && !_.has(urlGenerated, "path")) {
                urlGenerated["path"] = urlGenerated["url"];
              }
              this.finalDocuments.push(urlGenerated);

              if (temp_count >= mapper.length) {
                this.$emit("onUploadComplete", {
                  payLoadKey: this.payLoadKey,
                  finalDocuments: this.finalDocuments,
                  indexValue: this.indexValue,
                  payLoadDocumentsKey: this.payLoadDocumentsKey,
                  allowMultiple: this.allowMultiple,
                  takeIndex: this.takeIndex,
                });
                // this.documents = [];
              }

              doc.url = urlGenerated;
              mapper[index] = doc;
            });
          });
        });
        model.splice(0, mapper.length, ...mapper);
      }
    },

    beforeCreate() {
      //console.log(this.showLoading);
    },
  },
};
</script>
